<template>
  <div>
    <b-card no-body class="mb-5">
      <b-card-header header-tab="header" class="p-1" role="tab">
        <b-button block v-b-toggle.accordion-2 variant="link" class="text-left font-weight-bold text-dark pl-8 pr-8 fs-102 collapsed-with-icon">
          {{ $t('advanced_filter') }}
        </b-button>
      </b-card-header>

      <b-collapse id="accordion-2" visible accordion="my-accordion" role="tabpanel">
        <b-card-body>
          <div class="m-form m-form--fit m--margin-bottom-20">
            <div class="row mb-5">
              <div class="form-group col-md-6 mt-2 mb-2">
                <label>{{ $t('tax_report.branch') }}</label>
                <multiselect v-model="branches_ids"
                             :placeholder="$t('tax_report.branch')"
                             label="name"
                             track-by="id"
                             :options="branches"
                             :multiple="false"
                             @input="getIds('branches')"
                             :taggable="false"
                             :show-labels="false"
                             :show-no-options="false"
                             :show-no-results="false">
                </multiselect>
              </div>
              <div class="form-group col-md-6 mt-2 mb-2">
                <label for="from_date">{{ $t('tax_report.tax') }}</label>
                <multiselect v-model="taxes_ids"
                             :placeholder="$t('tax_report.tax')"
                             label="name"
                             track-by="id"
                             :options="taxes"
                             :multiple="false"
                             @input="getIds('taxes')"
                             :taggable="false"
                             :show-labels="false"
                             :show-no-options="false"
                             :show-no-results="false"
                             @search-change="getTaxes($event)">
                </multiselect>
              </div>
              <div class="form-group col-md-6 mt-2 mb-2">
                <label>{{ $t('currency') }}</label>
                <select id="currency_id" v-model="filters.f_currencies_ids" class="custom-select" name="">
                  <option v-for="row in currencies" :key="row.id" :value="row.id">{{ row.name }}</option>
                </select>
              </div>

              <div class="form-group col-md-6 mt-2 mb-2">
                <label for="from_date">{{ $t('tax_report.from_date') }}</label>
                <input v-model="filters.from_date" type="date" id="from_date" class="form-control">
              </div>
              <div class="form-group col-md-6 mt-2 mb-2">
                <label for="to_date">{{ $t('tax_report.to_date') }}</label>
                <input v-model="filters.to_date" type="date" id="to_date" class="form-control">
              </div>
              <div class="col-md-6 mt-2 mb-2 pt-10">
                <div>
                  <b-button-group>
                    <b-button @click="changeIntervalType('current_day')" :variant="filters.period=='current_day'? 'primary' : 'outline-primary'">{{ $t('customer_transactions_report.current_date') }}</b-button>
                    <b-button @click="changeIntervalType('current_month')" :variant="filters.period=='current_month'? 'primary' : 'outline-primary'">{{ $t('customer_transactions_report.current_month') }}</b-button>
                    <b-button @click="changeIntervalType('current_year')" :variant="filters.period=='current_year'? 'primary' : 'outline-primary'">{{ $t('customer_transactions_report.current_year') }}</b-button>
                    <b-button @click="changeIntervalType('financial_year')" :variant="filters.period=='financial_year'? 'primary' : 'outline-primary'">{{ $t('customer_transactions_report.financial_year') }}</b-button>
                    <!--                    <b-button  @click="changeFinancialYear" :variant="filters.financial_year==1? 'primary' : 'outline-primary'">{{$t('customer_transactions_report.financial_year')}}</b-button>-->
                  </b-button-group>

                </div>
                <div class="mt-5">
                  <b-button-group>
                    <b-button @click="changeIntervalType('yesterday')" :variant="filters.period=='yesterday'? 'primary' : 'outline-primary'">{{ $t('customer_transactions_report.yesterday') }}</b-button>
                    <b-button @click="changeIntervalType('last_month')" :variant="filters.period=='last_month'? 'primary' : 'outline-primary'">{{ $t('customer_transactions_report.last_month') }}</b-button>
                    <b-button @click="changeIntervalType('last_year')" :variant="filters.period=='last_year'? 'primary' : 'outline-primary'">{{ $t('customer_transactions_report.last_year') }}</b-button>
                  </b-button-group>

                </div>
              </div>

              <div class="form-group d-inline-flex col-md-6 mt-10">
                <button type="button" @click="doFilter" class="btn btn-primary mt-auto mr-2" id="m_search">
                  {{ $t('display_report') }}
                </button>
                <button type="button" @click="resetFilter" class="btn btn-danger mt-auto mr-2" id="resetFilter">
                  <i class="fas fa-trash-restore"></i> {{ $t('reset_search') }}
                </button>
              </div>
            </div>
          </div>
        </b-card-body>
      </b-collapse>
    </b-card>

    <!-- End Filter -->
    <div class="card card-custom">
      <div class="card-body">
        <div class="mt-3">
          <div class="row mb-10">
            <div class="col-md-12 mt-10" id="summaryTableDetailsTitle">
              <h4 class="text-center">{{ $t('MENU.tax_report') }}</h4>
              <h5 class="text-center" v-if="filters.from_date && filters.to_date">{{ $t('from') }} {{ filters.from_date }} {{ $t('to') }} {{ filters.to_date }}</h5>
            </div>

          </div>
          <div class="row mb-10">
            <div class="col-12">
              <b-tabs content-class="mt-3">
                <b-tab :title="$t('summery')" active>
                  <div class="table-responsive">
                    <div class="row justify-content-end p-4">
                      <b-dropdown right :text="$t('export')" class="mr-2 ml-2">
                        <button class="dropdown-item" @click="convertTableToExcelReport('summaryTable')">
                          <span><i class="fa fa-file-excel"></i> <span>{{ $t('excel') }}</span></span>
                        </button>

                        <button class="dropdown-item" @click="printData('summaryTable')">
                          <span><i class="fa fa-print"></i> <span>{{ $t('print') }}</span></span>
                        </button>
                        <button class="dropdown-item" @click="getAllDataForExport('sectionForExport', 'excel')">
                          <span><i class="fa fa-file-excel"></i> <span>{{ $t('all_data') }}</span></span>
                        </button>

                      </b-dropdown>
                    </div>
                    <table class="table table-bordered" id="summaryTable">
                      <tbody>
                      <tr>
                        <th>{{ $t('tax_report.tax') }}</th>
                        <th>{{ $t('tax_report.taxable_amount') }}</th>
                        <th>{{ $t('tax_report.tax') }}</th>
                      </tr>
                      <template v-for="(row, index) in data_summery">
                        <tr>
                          <td colspan="3">{{ row.label }}</td>
                        </tr>
                        <tr v-for="(row2, index2) in row.items" :key="'summery_'+uniqueKey()">
                          <td>{{ row2.label }}</td>
                          <td>{{ row2.total_applied_amount }}</td>
                          <td>{{ row2.total_tax_value }}</td>
                        </tr>
                        <tr>
                          <td>{{ $t('tax_report.total') }}</td>
                          <td>{{ row.total_applied_amount ? row.total_applied_amount : '0' }}</td>
                          <td>{{ row.total_tax_value ? row.total_tax_value : '0' }}</td>
                        </tr>
                      </template>

                      </tbody>
                      <tfoot>
                      <tr>
                        <th>{{ $t('tax_report.total') }}</th>
                        <td>{{ summery_total_sum.total_applied_amount ? summery_total_sum.total_applied_amount : 0 }}</td>
                        <td>{{ summery_total_sum.total_tax_value ? summery_total_sum.total_tax_value : 0 }}</td>
                      </tr>
                      </tfoot>
                    </table>
                  </div>
                  <div class="col-12 text-center" v-if="page">
                    <button class="btn btn-warning" @click="loadMore">
                      <p class="mb-0">
                        <b-icon icon="arrow-counterclockwise" font-scale="1"></b-icon>
                        {{ $t('load_more') }}
                      </p>
                    </button>
                  </div>
                </b-tab>
                <b-tab :title="$t('details')">
                  <div class="table-responsive">
                    <div class="row justify-content-end p-4">
                      <b-dropdown right :text="$t('export')" class="mr-2 ml-2">
                        <button class="dropdown-item" @click="convertTableToExcelReport('DetailsTable')">
                          <span><i class="fa fa-file-excel"></i> <span>{{ $t('excel') }}</span></span>
                        </button>

                        <button class="dropdown-item" @click="printData('DetailsTable')">
                          <span><i class="fa fa-print"></i> <span>{{ $t('print') }}</span></span>
                        </button>
                        <button class="dropdown-item" @click="getAllDataForExportDetails('sectionForExportDetails', 'excel')">
                          <span><i class="fa fa-file-excel"></i> <span>{{ $t('all_data') }}</span></span>
                        </button>

                      </b-dropdown>
                    </div>
                    <table class="table table-bordered" id="DetailsTable">
                      <tbody>
                      <tr>

                        <th>{{ $t('tax_report.id') }}</th>
                        <th>{{ $t('tax_report.financier') }}</th>
                        <th>{{ $t('tax_report.commercial_register') }}</th>
                        <th>{{ $t('tax_report.tax_register') }}</th>
                        <th>{{ $t('tax_report.date') }}</th>
                        <th>{{ $t('tax_report.item') }}</th>
                        <th>{{ $t('tax_report.description') }}</th>
                        <th>{{ $t('tax_report.taxable_amount') }}</th>
                        <th>{{ $t('tax_report.tax') }}</th>
                      </tr>
                      <template v-for="(row, index) in data_details">
                        <tr>
                          <td colspan="9">{{ row.label }}</td>
                        </tr>
                        <template v-for="(row2, index2) in row.items">
                          <tr>
                            <td colspan="9">{{ row2.label }}</td>
                          </tr>
                          <tr v-for="(row3, index3) in row2.items" :key="'tax2_'+ uniqueKey()">
                            <td>{{ row3.id }}</td>
                            <td>{{ row3.name }}</td>
                            <td>{{ row3.commercial_register }}</td>
                            <td>{{ row3.tax_register }}</td>
                            <td>{{ row3.created_at }}</td>
                            <td>{{ row3.item_name }} - {{row3.item_code}}</td>
                            <td>{{ row3.invoice_type_with_code }}</td>
                            <td>{{ row3.applied_amount }}</td>
                            <td>{{ row3.tax_value }}</td>
                          </tr>
                          <tr>
                            <td colspan="7">{{ $t('tax_report.total') }}</td>
                            <td>{{ row2.total_applied_amount ? row2.total_applied_amount : '0' }}</td>
                            <td>{{ row2.total_tax_value ? row2.total_tax_value : '0' }}</td>
                          </tr>

                        </template>
                        <tr>
                          <td colspan="7">{{ $t('tax_report.total') }}</td>
                          <td>{{ row.total_applied_amount ? row.total_applied_amount : '0' }}</td>
                          <td>{{ row.total_tax_value ? row.total_tax_value : '0' }}</td>
                        </tr>
                      </template>

                      </tbody>
                      <tfoot>
                      <tr>
                        <th colspan="7">{{ $t('tax_report.total') }}</th>
                        <td>{{ details_total_sum.total_applied_amount ? details_total_sum.total_applied_amount : 0 }}</td>
                        <td>{{ details_total_sum.total_tax_value ? details_total_sum.total_tax_value : 0 }}</td>
                      </tr>
                      </tfoot>
                    </table>
                  </div>
                  <div class="col-12 text-center" v-if="page_details">
                    <button class="btn btn-warning" @click="loadMoreDetails">
                      <p class="mb-0">
                        <b-icon icon="arrow-counterclockwise" font-scale="1"></b-icon>
                        {{ $t('load_more') }}
                      </p>
                    </button>
                  </div>
                </b-tab>
                <template #tabs-end>
                  <li class="nav-item  d-flex my-auto">
                    <select id="" v-model="filters.group_by" class="custom-select border-0 pt-0 pb-0 h-auto shadow-none" name="">
                      <option :value="null" disabled>{{ $t('select_filter') }}</option>
                      <option value="invoice">{{ $t('tax_report.invoice') }}</option>
                      <option value="item">{{ $t('tax_report.item') }}</option>
                    </select>

                  </li>
                </template>
              </b-tabs>
            </div>
          </div>
        </div>

      </div>
    </div>
    <!--end::supplier-->

    <div class="export-container">
      <div class="section-for-export">
        <table class="table table-bordered" id="sectionForExport">
          <tbody>
          <tr>
            <th>{{ $t('tax_report.tax') }}</th>
            <th>{{ $t('tax_report.taxable_amount') }}</th>
            <th>{{ $t('tax_report.tax') }}</th>
          </tr>
          <template v-for="(row, index) in dataForExportSummery">
            <tr>
              <td colspan="3">{{ row.label }}</td>
            </tr>
            <tr v-for="(row2, index2) in row.items" :key="'summery8855_'+uniqueKey()">
              <td>{{ row2.label }}</td>
              <td>{{ row2.total_applied_amount }}</td>
              <td>{{ row2.total_tax_value }}</td>
            </tr>
            <tr>
              <td>{{ $t('tax_report.total') }}</td>
              <td>{{ row.total_applied_amount ? row.total_applied_amount : '0' }}</td>
              <td>{{ row.total_tax_value ? row.total_tax_value : '0' }}</td>
            </tr>
          </template>

          </tbody>
          <tfoot>
          <tr>
            <th>{{ $t('tax_report.total') }}</th>
            <td>{{ dataTotalsForExportSummery.total_applied_amount ? dataTotalsForExportSummery.total_applied_amount : 0 }}</td>
            <td>{{ dataTotalsForExportSummery.total_tax_value ? dataTotalsForExportSummery.total_tax_value : 0 }}</td>
          </tr>
          </tfoot>
        </table>

        <table class="table table-bordered" id="sectionForExportDetails">
          <tbody>
          <tr>

            <th>{{ $t('tax_report.id') }}</th>
            <th>{{ $t('tax_report.financier') }}</th>
            <th>{{ $t('tax_report.commercial_register') }}</th>
            <th>{{ $t('tax_report.tax_register') }}</th>
            <th>{{ $t('tax_report.date') }}</th>
            <th>{{ $t('tax_report.item') }}</th>
            <th>{{ $t('tax_report.description') }}</th>
            <th>{{ $t('tax_report.taxable_amount') }}</th>
            <th>{{ $t('tax_report.tax') }}</th>
          </tr>
          <template v-for="(row, index) in dataForExportDetails">
            <tr>
              <td colspan="9">{{ row.label }}</td>
            </tr>
            <template v-for="(row2, index2) in row.items">
              <tr>
                <td colspan="9">{{ row2.label }}</td>
              </tr>
              <tr v-for="(row3, index3) in row2.items" :key="'tax2888_'+ uniqueKey()">
                <td>{{ row3.id }}</td>
                <td>{{ row3.name }}</td>
                <td>{{ row3.commercial_register }}</td>
                <td>{{ row3.tax_register }}</td>
                <td>{{ row3.created_at }}</td>
                <td>{{ row3.item_name }} - {{row3.item_code}}</td>
                <td>{{ row3.invoice_type_with_code }}</td>
                <td>{{ row3.applied_amount }}</td>
                <td>{{ row3.tax_value }}</td>
              </tr>
              <tr>
                <td colspan="7">{{ $t('tax_report.total') }}</td>
                <td>{{ row2.total_applied_amount ? row2.total_applied_amount : '0' }}</td>
                <td>{{ row2.total_tax_value ? row2.total_tax_value : '0' }}</td>
              </tr>

            </template>
            <tr>
              <td colspan="7">{{ $t('tax_report.total') }}</td>
              <td>{{ row.total_applied_amount ? row.total_applied_amount : '0' }}</td>
              <td>{{ row.total_tax_value ? row.total_tax_value : '0' }}</td>
            </tr>
          </template>

          </tbody>
          <tfoot>
          <tr>
            <th colspan="7">{{ $t('tax_report.total') }}</th>
            <td>{{ dataTotalsForExportDetails.total_applied_amount ? dataTotalsForExportDetails.total_applied_amount : 0 }}</td>
            <td>{{ dataTotalsForExportDetails.total_tax_value ? dataTotalsForExportDetails.total_tax_value : 0 }}</td>
          </tr>
          </tfoot>
        </table>
      </div>
    </div>
    <div class="export-container">
      <div class="section-for-export" id="sectionForExportHeader">
        <report-main-header :print-type="'TaxesReport'"></report-main-header>
      </div>
    </div>


  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import TableToExcel from "@linways/table-to-excel";
import ReportMainHeader from "@/view/content/printing-heads/ReportMainHeader.vue";
import cssStypeForPrintReport from "@/core/config/mix/cssStypeForPrintReport";

export default {
  name: "index-taxes-report",
  components: {ReportMainHeader},
  data() {
    return {
      mainRoute: '/v2/reports/accounts/taxes/details',
      mainRouteSummery: '/v2/reports/accounts/taxes/summary',
      mainRouteDependency: 'base/dependency',


      data_details: [],
      details_total_sum: [],


      data_summery: [],
      summery_total_sum: [],

      dataForExportDetails: [],
      dataTotalsForExportDetails: [],

      dataForExportSummery: [],
      dataTotalsForExportSummery: [],

      filters: {
        from_date: null,
        to_date: null,

        f_branches_ids: null,
        f_taxes_ids: null,
        f_currencies_ids: null,

        period: null,
        range: null,
        f_time_period: 0,
        group_by: 'invoice',
      },
      json_meta: [
        [{
          key: "charset",
          value: "utf-8",
        },],
      ],
      page: 1,
      page_details: 1,

      taxes: [],
      branches_ids: null,
      branches: [],
      taxes_ids: null,
      currencies: [],
      currency_default_id: null,
    }
  },
  watch: {
    "filters.group_by": function (val) {
      if (val)
        this.doFilter();
    },
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t('MENU.accounting_reports'), route: '/reports/accounting-reports'}, {title: this.$t("MENU.tax_report")}]);
    this.defaultDataForUser();
    this.getCurrencies();
    this.getTaxes();
    this.getBranches();
  },
  methods: {
    ...cssStypeForPrintReport,
    getRandom() {
      return Math.floor(Math.random() * 10000);
    },
    getIds(related_with) {
      switch (related_with) {
        case 'branches':
          this.filters.f_branches_ids = this.branches_ids.id;
          break;
        case 'taxes':
          this.filters.f_taxes_ids = this.taxes_ids.id;
          break;
      }
    },
    changeIntervalType(action_type, range = null) {
      if (this.filters.period == action_type) {
        this.filters.period = null;
        this.filters.f_time_period = 0;
      } else {
        this.filters.period = action_type;
        this.filters.f_time_period = 1;
      }

    },
    doFilter() {
      this.page = 1;
      this.page_details = 1;
      this.getReportSummery();
      this.getReportDetails();

    },
    loadMore() {
      this.page = this.page ? (this.page + 1) : 1;
      this.getReportSummery();
    },
    loadMoreDetails() {
      this.page_details = this.page_details ? (this.page_details + 1) : 1;
      this.getReportDetails();
    },
    resetFilter() {
      this.filters.period = null;
      this.filters.range = null;
      this.filters.f_time_period = 0;
      this.filters.from_date = null;
      this.filters.to_date = null;

      this.filters.f_branches_ids = null;
      this.filters.f_taxes_ids = null;
      this.filters.f_currencies_ids = this.currency_default_id;
      this.taxes_ids = null;
      this.branches_ids = null;

      this.doFilter();
    },
    getCurrencies() {
      ApiService.get(this.mainRouteDependency + "/currencies").then((response) => {
        this.currencies = response.data.data;
      });
    },
    defaultDataForUser() {
      ApiService.get(this.mainRouteDependency + "/default_data_for_user").then((response) => {
        this.filters.f_currencies_ids = response.data.data.currency_id;
        this.currency_default_id = response.data.data.currency_id;
      });
    },

    getTaxes() {
      ApiService.get(`${this.mainRouteDependency}/taxes`).then((response) => {
        this.taxes = response.data.data;
      });
    },
    getBranches() {
      ApiService.get(this.mainRouteDependency + "/branches").then((response) => {
        this.branches = response.data.data;
      });
    },
    uniqueKey() {
      return Math.floor(Math.random() * 10000000000) + '_' + Date.now();
    },

    /*****
     * details
     */

    getReportDetails() {
      let _limit = (this.page ? this.page : 1) * 25
      ApiService.get(this.mainRoute, {params: {...this.filters, limit: _limit}}).then((response) => {
        this.data_details = response.data.data.data;
        this.details_total_sum = response.data.data.totals;
      });
    },
    getAllDataForExportDetails(tableId, _export) {
      ApiService.get(this.mainRoute, {params: {...this.filters, is_export: 1}}).then((response) => {
        this.dataForExportDetails = response.data.data.data;
        this.dataTotalsForExportDetails = response.data.data.totals;

        this.convertTableToExcelReport(tableId);
      });
    },
    getReportSummery() {
      let _limit = (this.page ? this.page : 1) * 25
      ApiService.get(this.mainRouteSummery, {params: {...this.filters, limit: _limit}}).then((response) => {
        this.data_summery = response.data.data.data;
        this.summery_total_sum = response.data.data.totals;
      });
    },
    getAllDataForExport(tableId, _export) {
      ApiService.get(this.mainRouteSummery, {params: {...this.filters, is_export: 1}}).then((response) => {
        this.dataForExportSummery = response.data.data.data;
        this.dataTotalsForExportSummery = response.data.data.totals;

        this.convertTableToExcelReport(tableId);
      });
    },

    printData(tableId) {
      this.setReportLog('pdf', 'taxes report');
      let divToPrint = document.getElementById(tableId);
      let divTitleToPrint = document.getElementById('sectionForExportHeader');
      let newWin = window.open("");
      newWin.document.write('<meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">');
      newWin.document.write("<link rel='stylesheet' href='https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/css/bootstrap.min.css'>");
      newWin.document.write('<style>');
      newWin.document.write(this.getCssStyleForPrintReport());
      newWin.document.write('</style>');
      newWin.document.write(divTitleToPrint.outerHTML + divToPrint.outerHTML);
      setTimeout(() => {
        newWin.print();
        // newWin.close();
      }, 100)
    },

    convertTableToExcelReport(tableId) {
      this.setReportLog('excel', 'taxes report');
      setTimeout(() => {
        TableToExcel.convert(document.getElementById(tableId), {
          name: this.$t('MENU.tax_report') + '.xlsx',
          sheet: {
            name: 'Sheet 1'
          }
        });
      }, 100)
    },
    /**
     * register log
     */
    setReportLog(key, description) {
      ApiService.post("reports/system_activity_log/log_type", {
        key: key,
        description: description,
      });
    }

  },
};
</script>
